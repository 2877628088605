@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

:root {
    --button-red: #ae0508;
    --button-login: #d6901e;
    --background-color: #202020;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: var(--background-color);
    width: 100%;
    height: 100%;
}

.flex-box {
    display: flex;
    justify-content: space-around;
    vertical-align: middle;
}

.list ul li {
    display: inline-block;
    margin-top: 12px;
    margin-left: 5px;
    vertical-align: middle;
}

#user {
    position: absolute;
    left: 10px;
    top: 7px;
    color: var(--button-login);
}

.login-nav {
    position: relative;
    margin-left: 10px;
}

.input-field {
    background-color: transparent;
    width: 160px;
    max-width: 100%;
    padding-left: 25px;
    border-radius: 50px;
    outline: none;
    border: 1px solid gray;
    height: 26px;
    color: white;
    font-size: 13px;
}

.input-field::placeholder {
    font-size: 13px;
    position: relative;
    bottom: 0;
}

.button-nav {
    width: 80px;
    position: relative;
    border: none;
    border-radius: 50px;
    color: white;
    padding: 2px 0px;
    font-size: 14px;
}

.colornav {
    background-image: linear-gradient(to bottom, #dbac54 0%, #dbac54 50%, #d6901e 0%, #d6901e 100%);
}

.btn-red {
    background: var(--button-red);
}

.btn-yellow {
    background: var(--button-login);
}

.nav-link {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 14px;
    color: black;
    font-family: 'Poppins', sans-serif;
}

.banner {
    width: 100%;
    min-height: auto;
}

.input-search {
    background: #d6a140;
    border: none;
    outline: none;
    border-radius: 50px;
    padding: 5px 30px;
    color: white;

}

.input-search::placeholder {
    font-size: 15px;
    color: aliceblue;
    font-family: 'Poppins', sans-serif;
}

#search {
    position: absolute;
    right: 10px;
    top: 10px;
    color: white;
}

div>span {
    text-transform: uppercase;
    color: white;
    font-family: 'Poppins', sans-serif;
}

.bg-bottom-nav {
    background-color: rgb(65, 65, 65);
    width: 100%;
}

.sidebar {
    background-color: black;
    /* position: fixed; */
    /* width: 300px; */
    height: auto;
    padding: 20px;
}

.sidebar li {
    display: flex;
    justify-content: space-between;
    margin: 1.4rem 0;
    border-bottom: 2px dotted;
}

.sidebar li>span {
    font-size: 15px;
    color: white;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
}

.box-relative {
    position: relative;
}

.flex-img {
    display: flex;
    justify-content: space-between;
}

.img-bg {
    transition: 0.5s;
    cursor: pointer;
    position: relative;
    border: 4px solid #d6901e;
    /* background-color: black;
    opacity: 0.2; */
}

.show-on {
    background-color: black;
    opacity: 0.1;
}

.transition-box {
    position: absolute;
    top: 26px;
    left: -10px;
    right: 0;
}

.boxhide {
    display: none;
}

.colmn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
}

.colmn>button {
    position: relative;
    margin: 10px 0px;
    /* padding: 5px 10px; */
    width: 100px;
    height: 35px;
    border-radius: 50px;
    color: white;
    outline: none;
    border: none;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    box-shadow: 1px 2px 3px black;
}

.colmn>button:nth-child(1) {
    background-color: var(--button-login)
}

.colmn>button:nth-child(2) {
    background-color: var(--button-red)
}

.newtag {
    position: absolute;
    top: 0;
    left: 0;
    padding: 3px;
    width: 60px;
    height: 60px;
    font-size: 15px;
    /* transform: rotate(-45deg); */
    background-color: black;
    clip-path: polygon(0 0, 0 100%, 100% 0);
}

.newtag p {
    padding-bottom: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    transform: rotate(-45deg);
}

.p-rating .p-rating-item.p-rating-item-active .p-rating-icon,
.icon-yellow {
    color: #d6901e !important;
}

.title-img {
    font-size: 13px;
    color: var(--button-login);
}

.bg-footer {
    background-color: #141212;
    padding: 30px;
    position: relative;
    top: 20px;
}

.footer-column>h4 {
    font-size: 15px;
    font-weight: bold;
    color: var(--button-login);
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
}

.service h4 {
    margin: 20px 0px;
    font-size: 15px;
    font-weight: bold;
    color: var(--button-login);
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
}

.depo {
    position: relative;
    top: 20px;
}

.footer-column>h6 {
    font-size: 15px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
}

.footer-column>p {
    text-align: justify;
    color: rgb(105, 104, 104);
}

.footer-column>h4:nth-child(2),
.footer-column>h4:nth-child(3) {
    font-size: 15px !important;
    margin: 28px 0px;
}

.list-column>li {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.info {
    font-size: 15px;
    color: white;
    text-transform: uppercase;
}

.footer-side li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
}

.footer-contact {
    display: flex;
    position: relative;
    top: 1px;
    justify-content: flex-start;
    flex-flow: column;
}

.footer-contact li {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 14px 0px;
}

.list-info li {
    vertical-align: middle;
    list-style: none;
    margin: -4px 20px;
}

.list-info li>span {
    font-size: 16px;
    text-transform: capitalize;
    color: rgb(231, 231, 231);
}

.list-info li small,
.list-info li a {
    color: rgb(175, 175, 175);
}

.option-list li {
    margin: 0px 20px;
    vertical-align: middle;
}

.text-yellow {
    color: #a07b41;
}

.footer-list li {
    display: inline-block;
}

.footer-list li>span {
    font-size: 15px !important;
    text-transform: capitalize;
    color: rgb(117, 117, 117);
}

.copyright>span {
    font-size: 15px !important;
    color: rgb(117, 117, 117);
}

.border-btm {
    padding: 6px;
    border-bottom: 1px solid white;
}

.footer-info {
    font-size: 20px;
    text-transform: uppercase;
    color: rgb(117, 117, 117);
}

.box-border-btm {
    width: 100%;
    height: 2px;
    margin-top: 1.7rem;
    position: relative;
    background-color: rgb(114, 114, 114);
}

.information-list div>li {
    display: block;
    list-style: none;
    margin: 14px 0px;
}

.information-list div li>h5 {
    font-size: 18px;
    color: #ae0508;
    text-transform: capitalize;
}

.information-list div li>p {
    font-size: 15px;
    color: rgb(117, 117, 117);
}

.progress {
    position: relative;
    top: -20px;
    background-color: #e9ecef2e;
}

.progress-bar {
    background-color: #ae0508;
}

.num {
    font-size: 30px;
    position: relative;
    top: -15px;
    color: #ae0508;
}

.text-mins {
    font-size: 14px !important;
    color: rgb(117, 117, 117) !important;
}

.top-move {
    position: relative;
}

@media only screen and (max-width: 991px) {
    .align-text{
        flex-flow: column wrap;
    }
    .footer-list li {
        display: block;
        margin: 10px 0px;
    }
}